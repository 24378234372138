import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from "./header/header";
import Home from './home/home';
import Contact from './contact/contact';
import Portfolio from './portfolio/portfolio';
import Services from './services/services';
import Prikol from './portfolio/prikol/prikol';
import Footer from './footer/footer';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  return (
    <Router>
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/portfolio' element={<Portfolio />} />
          <Route path='/services' element={<Services />} />
          <Route path='/business_presentation' element={<Prikol />} />
          <Route path='/personal_portfolio' element={<Prikol />} />
          <Route path='/online_store' element={<Prikol />} />
        </Routes>
    </Router>
  );
}

export default App;
