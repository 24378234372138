import './header.css';
import { Link } from 'react-router-dom';
import logo from './image.png';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';

function Header() {
    const location = useLocation();
    const [isActive, setIsActive] = useState(false);

    const handleClick = () => {
        setIsActive(!isActive);
    };

    useEffect(() => {
        setIsActive(false);
    }, [location.pathname]);

    return (
        <div className='wrapper'>
            <header className={`header ${isActive ? 'active' : ''}`}>
                <div className='container'>
                    <div className='header__body'>
                        <Link to="/" className='header__logo'>
                            <img src={logo} alt="Logo"/>
                        </Link>
                        <div className='web__head'>
                            <h1>WHITEWEB WORKS</h1>
                        </div>
                        <div className='upper__links'>
                            <Link to={{ pathname: "/", state: { fromHeader: true } }}>About</Link>
                            <Link to="/services">Our Services</Link>
                        </div>
                        <div className={`header__burger ${isActive ? 'active' : ''}`} onClick={handleClick}>
                            <span></span>
                        </div>
                        <nav className={`header__menu ${isActive ? 'active' : ''}`}>
                            <ul className="header__list">
                                <li>
                                    <button className={`header__link ${location.pathname === '/' ? 'active' : ''}`}><Link to="/">About Us</Link></button>
                                </li>
                                <li>
                                    <button id = 'team_btn'className={`header__link_team${location.pathname === '/team' ? 'active' : ''}` }><Link to="/team">Team</Link></button>
                                </li>
                                <li>
                                    <button className={`header__link ${location.pathname === '/services' ? 'active' : ''}`}><Link to="/services">Our Services</Link></button>
                                </li>
                                <li>
                                    <button className={`header__link ${location.pathname === '/contact' ? 'active' : ''}`}><Link to="/contact">Contact Us</Link></button>
                                </li>
                                <li>
                                    <button className={`header__link ${location.pathname === '/portfolio' ||location.pathname === '/business_presentation'||location.pathname === '/personal_portfolio'||location.pathname === '/online_store' ? 'active' : ''}`}><Link to="/portfolio">Portfolio</Link></button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </header>
        </div>
    );
}

export default Header;