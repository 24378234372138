import './portfolio.css';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import buss from './buss.png';
import pers from './pers.png';
import store from './store.png';
import Footer from '../footer/footer';

function Portfolio() {
    return (
        <div className='portfolio__wrap'>
        <div className='port__wrap'>
            <div className='cont__wr'>
                <div className='bus_pres'>
                    <div className="mySwiper"data-aos="fade-up">
                        <img src={buss} alt="Description of the image"/>
                    </div>
                </div>
                <div className='pers_port'>
                <div className="mySwiper" data-aos="fade-up">
                        <img src={pers}></img>
                    </div>
                </div>
                <div className='e_store'>
                <div className="mySwiper" data-aos="fade-up">
                        <img src={store}></img>
                </div>
                </div>
            </div>
        </div>
        <Footer/>
        </div>
    );
}

export default Portfolio;