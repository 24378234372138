import './footer.css';
import React from 'react';
import logo from './www-removebg-preview.png';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <nav className="footer-nav">
        <Link to="/" className='foot_a'>About</Link>
        <Link to="/contact" className='foot_a'>Contact</Link>
        <Link to="/portfolio" className='foot_a'>Portfolio</Link>
        <Link to="/services" className='foot_a'>Services</Link>
      </nav>
      <p>info@whitewebworks.com</p>
      <p>+371 206 144 02</p>
    </footer>
  );
}

export default Footer;
