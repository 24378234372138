// src/components/Chatbot.js
import React, { useState, useRef } from 'react';
import './Chatbot.css';

const Chatbot = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [messages, setMessages] = useState([{ text: "Hello! How can I assist you today?", isBot: true }]);
    const [input, setInput] = useState("");
    const chatEndRef = useRef(null);

    const toggleChatbot = () => setIsOpen(!isOpen);

    const handleInputChange = (e) => setInput(e.target.value);

    const sendMessage = async () => {
        if (input.trim()) {
            // Add the user message to the chat
            const newMessages = [...messages, { text: input, isBot: false }];
            setMessages(newMessages);
            setInput("");
            chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
    
            try {
                // Send the message to the server
                const response = await fetch('https://belij01.pythonanywhere.com/generate', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        msg: input // Send the input as the "msg" field
                    })
                });
    
                if (!response.ok) {
                    throw new Error(`Server error: ${response.statusText}`);
                }
    
                // Process the streaming response from the server
                const reader = response.body.getReader();
                const decoder = new TextDecoder();
                let done = false;
                let accumulatedText = ''; // Accumulate text for the bot's message
    
                // Add an initial empty message for the bot to update dynamically
                setMessages((prevMessages) => [
                    ...prevMessages,
                    { text: '', isBot: true }
                ]);
    
                // Read data in chunks and update the last bot message
                while (!done) {
                    const { value, done: doneReading } = await reader.read();
                    done = doneReading;
    
                    // Decode the chunk and add it to the accumulated text
                    accumulatedText += decoder.decode(value, { stream: true });
    
                    // Clean the text by removing any "data:" prefix, if necessary
                    const cleanText = accumulatedText.replace(/data:\s*/g, '').trim();
    
                    // Update the last bot message in the chat
                    setMessages((prevMessages) => {
                        const updatedMessages = [...prevMessages];
                        updatedMessages[updatedMessages.length - 1] = { text: cleanText, isBot: true };
                        return updatedMessages;
                    });
    
                    // Smooth scroll to the latest message
                    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
                }
            } catch (error) {
                console.error("Error sending message to server:", error);
                setMessages((prevMessages) => [
                    ...prevMessages,
                    { text: 'Error: Unable to connect to the server.', isBot: true }
                ]);
            }
        }
    };

    return (
        <div className="chatbot">
            <button className="chatbot-toggle" onClick={toggleChatbot}>
                {isOpen ? "×" : "AI Helper"}
            </button>
            {isOpen && (
                <div className="chatbot-window">
                    <div className="chatbot-header">
                        AI Helper
                    </div>
                    <div className="chatbot-messages">
                        {messages.map((msg, index) => (
                            <div key={index} className={`message ${msg.isBot ? "bot" : "user"}`}>
                                {msg.text}
                            </div>
                        ))}
                        <div ref={chatEndRef} />
                    </div>
                    <div className="chatbot-input">
                        <input
                            type="text"
                            value={input}
                            onChange={handleInputChange}
                            placeholder="Type your message..."
                            onKeyDown={(e) => e.key === "Enter" && sendMessage()}
                        />
                        <button onClick={sendMessage}>Send</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Chatbot;
