import React from 'react';
import './prikol.css';
import { useLocation } from 'react-router-dom';
import store from './store.png';
import pers from './pers.png';
import busines from './busines.png';
import Footer from '../../footer/footer';

function Prikol() {
    const location = useLocation();
    let pic;
    let text;
    if (location.pathname === '/business_presentation') {
        pic = busines;
        text = 'Business Presentation Example';
    } else if (location.pathname === '/personal_portfolio') {
        pic = pers;
        text = 'Personal Portfolio example';
    } else {
        pic = store;
        text = 'Online Store Example';
    }

    return (
        <div className='prikol__wrap'>
        <div className='ex__wrap'>
            <h1>{text}</h1>
            <img src={pic} alt="Presentation" />
            <Footer/>
        </div>
        </div>
    );
}

export default Prikol;