import './about.css';

function About()

{
    return(
        <div className='about__wrap' id = "About">
            <h1>ABOUT US</h1>
            <p>Innovation starts with White Web Works</p>
            <div className='about__blocks'>
                <div className='about__block' data-aos="fade-left">
                    <h4>Our Mission</h4>
                    <p>We revolutionize the web, turning visions into digital masterpieces for clients globally.</p>
                </div>
                <div className='about__block' data-aos="fade-left">
                    <h4>Our Approach</h4>
                    <p>Fusing creativity with cutting-edge tech, we craft standout web solutions that redefine online presence.</p>
                </div>
                <div className='about__block' data-aos="fade-left">
                    <h4>Our Success</h4>
                    <p>Our websites propel brands forward, driving growth, revenue, and client acquisition to new heights.</p>
                </div>
            </div>
        </div>
    );
}
export default About;