import './services.css';
import dev from './dev.png';
import rocket from './rocket.png';
import idea from './idea.png';
import mobile from './image 3.png';
import design from './design.png';
import seo from './seo.png';
import { Link } from 'react-router-dom';
import Footer from '../footer/footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

function Services()
{
    useEffect(() => {
        AOS.init({
          duration: 1000,
          once: false,
        });
      }, []);
    return(
        <div className='servi__cont'>
         <div className='service__wrap'>
            <h1>Our Services</h1>
            <p className='romka'>First-class websites for your business</p>
            <div className='service__cont'>
            <div className='service__left' data-aos="fade-up">
                <h2>Wep Pages</h2>
                <div className='serv__cont'>
                <p>Expertise in custom web development. From sleek landing pages to dynamic apps, we deliver innovative solutions. Transforming ideas into exceptional websites, tailored to your unique needs.</p>
                <img src={dev}></img>
                </div>
                <button><Link to="/contact">Contact Us</Link></button>
                <a className='price__left'></a>
            </div>

            <div className='service__right big' data-aos="fade-up">
            <h2 className='rocket'>E-store</h2>
                <div className='serv__cont'>
                    <img src = {rocket} className='img__big1'></img>
                <p className=''>Crafting tailored e-commerce solutions. We specialize in building robust online stores, ensuring seamless transactions and engaging user experiences to drive your business forward in the digital marketplace.</p>
                </div>
                <button><Link to="/contact">Contact Us</Link></button>
                <a className='price__right'></a>
            </div>
            <div className='service__left' data-aos="fade-up">
                    <h2 className='idea_z'>Ideas</h2>
                    <div className='serv__cont idea'>
                        <p>Discover limitless possibilities with us. We specialize in web services tailored to your unique vision. From design to development, we transform ideas into digital masterpieces, driving your online success forward.</p>
                        <img src={idea} alt="Developer"></img>
                    </div>
                    <button><Link to="/contact">Contact Us</Link></button>
                    <a className='price__left'></a>
                </div>
            <div className='service__right' data-aos="fade-up">
            <h2 className='res_des'>Responsive Design</h2>
                <div className='serv__cont'>
                    <img src = {mobile}></img>
                <p>Ensuring seamless user experiences across all devices. Our focus on responsive design guarantees your website looks and functions flawlessly on desktops, tablets, and mobile phones, maximizing accessibility and engagement.</p>
                  
                </div>
                <button><Link to="/contact">Contact Us</Link></button>
                <a className='price__right'></a>
            </div>
            <div className='service__left' data-aos="fade-up">
                    <h2>Graphical Design</h2>
                    <div className='serv__cont'>
                        <p>Bringing creativity to life through stunning visuals. Our graphical design services specialize in crafting eye-catching graphics and layouts that enhance your brand identity and captivate your audience.</p>
                        <img src={design} alt="Developer"></img>
                    </div>
                    <button><Link to="/contact">Contact Us</Link></button>
                    <a className='price__left'></a>
                </div>
            <div className='service__right big' data-aos="fade-up">
                <h2 className='seo'>SEO</h2>
                <div className='serv__cont'>
                    <img src={seo} className='img__big2'/>
                <p>Elevating your online visibility with strategic SEO optimization. Our expertise ensures your website ranks higher in search engine results, driving organic traffic and boosting your digital presence effectively.</p>
                </div>
                <button><Link to="/contact">Contact Us</Link></button>
                <a className='price__right'></a>
            </div>
            </div>
            
        </div>
        <Footer/>
        </div>
    );
}

export default Services;