import React, { useState } from 'react';
import axios from 'axios';
import './contact.css';
import Footer from '../footer/footer';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email: '',
    number: '',
    info: '',
  });
  const [errors, setErrors] = useState({
    name: false,
    surname: false,
    email: false,
    number: false,
  });
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');

  // Валидация email
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  };

  // Валидация имени
  const validateUsername = (username) => {
    const usernameRegex = /^[A-Za-z ]{3,50}$/;
    return usernameRegex.test(username);
  };

  const validateUsername2 = (username2) => {
    const username2Regex = /^[A-Za-z ]{3,50}$/;
    return username2Regex.test(username2);
  };

  const validateNumber = (number) => {
    const phoneRegex = /^(?:\+?\d{1,3})?[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,4}$/;
    return phoneRegex.test(number);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let validationErrors = {};

    if (formData.name.trim() === '' || formData.surname.trim() === '' || formData.email.trim() === '' || formData.number.trim() === '' || formData.info.trim() === '') {
      alert('Please fill the form.');
      return;
    }

    if (!validateUsername(formData.name)) validationErrors.name = true;
    if (!validateUsername(formData.name)) validationErrors.surname = true;
    if (!validateEmail(formData.email)) validationErrors.email = true;
    if (!validateNumber(formData.number)) validationErrors.number = true;

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setShowNotification(true);
      setNotificationMessage('Please correct the errors in the form.');
      setTimeout(() => setShowNotification(false), 3000);
      return;
    }

    axios
      .post('https://belij01.pythonanywhere.com/form', formData)
      .then((response) => {
        setShowNotification(true);
        setNotificationMessage('Your message has been sent successfully!');
        setFormData({ name: '', surname: '', email: '', number: '', info: '' });
        setTimeout(() => setShowNotification(false), 3000);
      })
      .catch((error) => {
        console.error('Error sending data:', error);
        setShowNotification(true);
        setNotificationMessage('Error sending message. Please try again later.');
        setTimeout(() => setShowNotification(false), 3000);
      });
  };

  return (
    <div className="cont__wrap">
      <div className="contact__wrap" data-aos="fade-up">
        <h1>Contact Us</h1>
        <p>Your success is our mission</p>
        <div className="contact__inputs">
          <form onSubmit={handleSubmit}>
            <div className="input-group">
              <label htmlFor="name">First Name</label>
              <textarea
                name="name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                className={errors.name ? 'error' : ''}
              ></textarea>
            </div>
            <div className="input-group">
              <label htmlFor="surname">Last Name</label>
              <textarea
                name="surname"
                id="surname"
                value={formData.surname}
                onChange={handleChange}
                className={errors.surname ? 'error' : ''}
              ></textarea>
            </div>
            <div className="input-group">
              <label htmlFor="email">E-Mail</label>
              <textarea
                name="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                className={errors.email ? 'error' : ''}
              ></textarea>
            </div>
            <div className="input-group">
              <label htmlFor="number">Phone Number</label>
              <textarea
                name="number"
                id="number"
                value={formData.number}
                onChange={handleChange}
                className={errors.number ? 'error' : ''}
              ></textarea>
            </div>
            <div className="input-group">
              <label htmlFor="info">Describe what you would like to implement?</label>
              <textarea
                name="info"
                id="info"
                className="info"
                value={formData.info}
                onChange={handleChange}
                className={errors.info ? 'error' : ''}
              ></textarea>
            </div>
            <button type="submit">Send</button>
          </form>
        </div>

        {showNotification && (
          <div className="notification">
            {notificationMessage}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Contact;